.wrapperPedidos {
  padding: 0 1rem;
  width: 100%;
  @media (min-width: 992px) {
    margin: 0 auto;
    width: 70rem;
  }
  .pedidos {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }
    .date {
      color: var(--light);
      font-weight: 700;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      @media (min-width: 992px) {
        font-size: 1.6rem;
      }
    }
    .listProduct {
      width: 100%;
    }
  }
  .textBefore {
    @media (min-width: 992px) {
      font-size: 1.5rem;
    }
  }
}
