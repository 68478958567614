.header {
  position: fixed;
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  align-items: center;
  background-color: var(--light);
  z-index: 10000;
  @media (min-width: 992px) {
    grid-template-columns: 2fr 6fr;
  }
  .wrapper-logo {
    grid: 1/3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 0;
    .logo {
      width: 4rem;
    }
    .title {
      font-size: 1.7rem;
      color: var(--white);
      text-decoration: none;
      @media (min-width: 992px) {
        font-size: 1.4rem;
      }
    }
  }
  .wrapper-hamburguer {
    grid: 3/4;
    display: flex;
    justify-content: flex-end;
    @media (min-width: 992px) {
      display: none;
    }
  }
  .wrapperDesktopkNav {
    display: none;
    @media (min-width: 992px) {
      display: block;
    }
  }
}
.wrapperNav {
  position: fixed;
  margin-top: 6rem;
  background-color: var(--light);
  text-align: start;
  padding: 1rem;
  width: 50%;
  height: 100vh;
  z-index: 10;
}
