.section {
  padding-top: 6rem;
  min-height: 94.6vh;
  @media (min-width: 992px) {
    min-height: 92.6vh;
  }
  @media (min-width: 1400px) {
    min-height: 95vh;
  }
  .categorieTitle {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-family: var(--title);
    color: var(--light);
    @media (min-width: 992px) {
      margin: 1rem;
    }
  }
  .wrapperSelect {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    .selectClothes {
      width: 18rem;
      padding: 0.5rem;
      border-radius: 2rem;
      color: var(--black);
      border: solid 1px var(--action);
      outline: none;
    }
  }
}
