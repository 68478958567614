.cartProduct {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: 1rem;
  width: 100%;
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  .wrapperImg {
    grid-column: 1/2;
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    @media (min-width: 992px) {
      height: 12rem;
    }
    .img {
      width: 100%;
      max-height: 100%;
      object-fit: scale-down;
    }
  }
  .wrapperData {
    width: 100%;
    padding: 0 0.5rem;
    .data {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 17rem;
      color: var(--black);
      margin-bottom: 0.3rem;
      @media (min-width: 992px) {
        width: 60rem;
        font-size: 1.3rem;
      }
      .titleData {
        color: var(--action);
        font-weight: 700;
      }
    }
    .actionButton {
      cursor: pointer;
      text-align: center;
      padding: 0.3rem 0.5rem;
      border: none;
      border-radius: 0.3rem;
      color: var(--white);
      background-color: var(--danger);
      @media (min-width: 992px) {
        margin-top: 1rem;
        width: 18rem;
        font-size: 1.2rem;
        padding: 0.5rem;
        transition: ease-in-out 0.5s;
        &:hover {
          width: 24rem;
        }
      }
    }
  }
}
