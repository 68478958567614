.heart {
  fill: var(--heart);
  width: 1rem;
  margin: 0 0 0 0.5rem;
  box-sizing: border-box;
  animation: pulse 3s ease infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
